import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import Layout from '../../UtilComponents/Layout';
import styled from 'styled-components';
import { RadioButton } from 'primereact/radiobutton';
import StyledRadioButtonGroup from '../../../styles/StyledRadioButtonGroup';
import PipelineSearch from './PipelineSearch/PipelineSearch';
import PipelineWizard from './PipelineWizard/PipelineWizard';
import { Toast } from 'primereact/toast';

const Container = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledLandingHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    .main-logo {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        align-items: center;
        .title {
            width: 350px;
            img {
                margin-top: 15px;
                margin-bottom: 15px;
                width: 100%;
            }
        }
        .sub-title {
            font-size: 18px;
        }
    }
    margin-top: 20px;
    margin-bottom: 20px;
`;

const StyledSearchModeSelect = styled.div`
    width: 50%;
    max-width: 550px;
    min-width: 350px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    .title {
        font-weight: bold;
        font-size: 16px;
        margin-right: 10px;
    }
`;

const Home = () => {

    const [searchMode, setSearchMode] = useState('query');
    const [showDialog, setShowDialog] = useState(false);
	const toast = useRef(null);

    useEffect(() => {
        const hasShownDialog = localStorage.getItem('hasShownDialog') === 'true';

        if (!hasShownDialog) {
            setShowDialog(true);
        }

        if (toast.current) {
			toast.current.show({
			  severity: 'warn', 
			  summary: 'CoBE Status Update', 
			  detail: 
			  <span>
				  {'This version of CoBE is no longer receiving updates. Check the link  '}
					  <a style={{textDecoration: 'none', color: 'blue' }} href='http://beta.pmcobe.ca/' target='_blank'>
						  {'here'} 
					  </a>
				  {' to test out the new beta deployment that\'s soon to come.'}
			  </span>,
			  closable: true,
			  life: 10000000,
			});
	  	}
    }, []);

    const handleDialogClose = () => {
        setShowDialog(false);
        localStorage.setItem('hasShownDialog', 'true');

        // Sending data to GA if necessary
        if (window.gtag) {
            window.gtag('event', 'hide', {
                'event_category': 'Dialog Notifications',
                'event_label': '2024 Update Dialog First Time Closed'
            });
        }
    };

	const handleToastClose = () => {
        if (window.gtag) { //Sending data to GA
          window.gtag('event', 'close', {
            'event_category': 'Toast Notifications',
            'event_label': '2024 Update Toast Tutorial Page'
          });
        }
    };

    return(
        <Layout>
			<Toast ref={toast} style={{ position: 'static', width: '93%', marginTop: '15px' }}onHide={() => handleToastClose()} />            
            <Container>
                <StyledLandingHeader>
                    <div className='main-logo'>
                        <div className='title'>
                            <img alt='CoBE' src='/images/logos/logo.png' />
                        </div>
                        <div className='sub-title'><b>Co</b>mputational & <b>B</b>ench scientist <b>E</b>cosystem</div>
                    </div>
                </StyledLandingHeader>
                <StyledSearchModeSelect>
                    <div className='title'>Explore tools and pipelines by: </div>
                    <StyledRadioButtonGroup>
                        <div className='radiobutton'>
                            <RadioButton 
                                inputId="query" 
                                name="searchMode" 
                                value="query" 
                                onChange={(e) => setSearchMode(e.value)} 
                                checked={searchMode === 'query'}
                            />
                            <label htmlFor="query">Query</label>
                        </div>
                        <div className='radiobutton'>
                            <RadioButton 
                                inputId="wizard" 
                                name="searchMode" 
                                value="wizard" 
                                onChange={(e) => setSearchMode(e.value)} 
                                checked={searchMode === 'wizard'} 
                            />
                            <label htmlFor="wizard">Wizard</label>
                        </div>
                    </StyledRadioButtonGroup>
                </StyledSearchModeSelect>
                {
                    searchMode === 'query' && <PipelineSearch />
                }
                {
                    searchMode === 'wizard' && <PipelineWizard />
                }
            </Container>
        </Layout>
    );
}

export default Home;
