import React, { useRef, useState, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import styled from "styled-components";
import Layout from '../../UtilComponents/Layout';
import colors from "../../../styles/colors";
import Stats from './NewsComponents/Stats';
import TopStats from "./NewsComponents/TopStats";
import NewsFeed from "./NewsComponents/NewsFeed";


const Container = styled.div`
  width: 80%;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Section = styled.div`
  width: 50%;
  overflow: auto;
  border: 1px solid ${colors.border_gray};
  margin: 5px;
  padding-top:10px;
`;

const News = () => {
    const toast = useRef(null);
  
    const handleToastClose = () => {
        if (window.gtag) { //Sending data to GA
          window.gtag('event', 'close', {
            'event_category': 'Toast Notifications',
            'event_label': '2024 Update Toast News Page'
          });
        }
    };
  
    useEffect(() => {
        if (toast.current) {
			toast.current.show({
			  severity: 'warn', 
			  summary: 'CoBE Status Update', 
			  detail: 
			  <span>
				  {'This version of CoBE is no longer receiving updates. Check the link  '}
					  <a style={{textDecoration: 'none', color: 'blue' }} href='http://beta.pmcobe.ca/' target='_blank'>
						  {'here'} 
					  </a>
				  {' to test out the new beta deployment that\'s soon to come.'}
			  </span>,
			  closable: true,
			  life: 10000000,
			});
	  	}
    }, []);

    return(
        <Layout>
            <Toast ref={toast} style={{ position: 'static', width: '93%', marginTop: '15px' }}onHide={() => handleToastClose()} />
            <Container>
                <h1>News</h1>
                    <React.Fragment>
                        <SubContainer>
                            <Stats /> 
                        </SubContainer>
                        <SubContainer>
                            <Section>
                                <NewsFeed />
                            </Section>
                            <Section>
                                <TopStats />
                            </Section>
                        </SubContainer>
                    </React.Fragment>
            </Container>
        </Layout>
    );
}

export default News;
