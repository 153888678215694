import React, { useState, useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import styled from 'styled-components';
import Layout from '../../UtilComponents/Layout';
import { SubContainer } from '../Tutorial/TutorialStyles';
import colors from '../../../styles/colors';

const StyledTeam = styled.div`
    min-width: 680px; 
    display: flex;
    margin-bottom: 30px;
`;

const StyledTeamMember = styled.div`
    width: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .team-member-photo {
        width: 150px;
        height: 170px;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .team-member-text {
        margin-top: 5px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.5;
    }
    a {
        text-decoration: none;
        color: ${colors.hyperlink};
    }
`;

const Contact = () => {
    const toast = useRef(null);

    const handleToastClose = () => {
        if (window.gtag) { //Sending data to GA
          window.gtag('event', 'close', {
            'event_category': 'Toast Notifications',
            'event_label': '2024 Update Toast Contact Page'
          });
        }
    };

    useEffect(() => {
        if (toast.current) {
          	toast.current.show({
				severity: 'warn', 
				summary: 'CoBE Status Update', 
				detail: 
				<span>
					{'This version of CoBE is no longer receiving updates. Check the link  '}
						<a style={{textDecoration: 'none', color: 'blue' }} href='http://beta.pmcobe.ca/' target='_blank'>
							{'here'} 
						</a>
					{' to test out the new beta deployment that\'s soon to come.'}
				</span>,
				closable: true,
				life: 10000000,
          	});
        }
    }, []);


    return(
        <Layout>
            <Toast ref={toast} style={{ position: 'static', width: '93%', marginTop: '15px' }}onHide={() => handleToastClose()} />

            <SubContainer>
                <h1>Contact Us</h1>
                <h3>CoBE is in its early stages of existence and will grow faster through user feedback.</h3>
                <div className='section'>
                    Feedback can be submitted via {' '}
                    <a href='https://github.com/bhklab/COBE-Issues/issues' target='_blank' rel="noopener noreferrer">GitHub Issues</a>.
                    <div>For inquiries regarding the web interface, contact: <b>minoru.nakano@uhnresearch.ca</b>.</div>
                    <div>For general inquiries, contact: <b>paul.brogee@uhnresearch.ca</b>.</div>
                </div>
                <h3>CoBE Co-founders</h3>
                <StyledTeam>
                    <StyledTeamMember>
                        <div className='team-member-photo'>
                            <img className='img-flowchart' alt='Benjamin Haibe-Kains' src='/images/team/bh-large.jpg' />
                        </div>
                        <div className='team-member-text'>
                            <a href='https://www.pmgenomics.ca/bhklab/' target='_blank' rel="noopener noreferrer">Dr. Benjamin Haibe-Kains</a>
                        </div>
                    </StyledTeamMember>
                    <StyledTeamMember>
                        <div className='team-member-photo'>
                            <img className='img-flowchart' alt='Mathieu Lupien' src='/images/team/MathieuLupien.jpg' />
                        </div>
                        <div className='team-member-text'>
                        <a href='https://www.pmgenomics.ca/lupienlab/' target='_blank' rel="noopener noreferrer">Dr. Mathieu Lupien</a>
                        </div>
                    </StyledTeamMember>
                </StyledTeam>
                <h3>Team Leads</h3>
                <StyledTeam>
                    <StyledTeamMember>
                        <div className='team-member-photo'>
                            <img className='img-flowchart' alt='Ankita Nand' src='/images/team/ankita_nand.png' />
                        </div>
                        <div className='team-member-text'>
                            <div>Ankita Nand</div>
                            <div>Bioinformatician</div>
                            <div>
                                <a href='https://www.pmgenomics.ca/lupienlab/' target='_blank' rel="noopener noreferrer">Lupien Lab</a>
                            </div>
                        </div>
                    </StyledTeamMember>
                    <StyledTeamMember>
                        <div className='team-member-photo'>
                            <img className='img-flowchart' alt='Emily So' src='/images/team/Emily_So.jpeg' />
                        </div>
                        <div className='team-member-text'>
                            <div>Emily So</div>
                            <div>Bioinformatician</div>
                            <div>
                                <a href='https://www.pmgenomics.ca/bhklab/' target='_blank' rel="noopener noreferrer">Haibe-Kains Lab</a>
                            </div>
                        </div>
                    </StyledTeamMember>
                    <StyledTeamMember>
                        <div className='team-member-photo'>
                            <img className='img-flowchart' alt='Minoru Nakano' src='/images/team/minoru_nakano.jpg' />
                        </div>
                        <div className='team-member-text'>
                            <div>Minoru Nakano</div>
                            <div>Software Developer</div>
                            <div>
                                <a href='https://www.pmgenomics.ca/bhklab/' target='_blank' rel="noopener noreferrer">Haibe-Kains Lab</a>
                            </div>
                        </div>
                    </StyledTeamMember>
                    <StyledTeamMember>
                        <div className='team-member-photo'>
                            <img className='img-flowchart' alt='Parinaz Nasr Esfahani' src='/images/team/parinaz_nasr_esfahani.jpg' />
                        </div>
                        <div className='team-member-text'>
                            <div>Parinaz Nasr Esfahani</div>
                            <div>Software Developer</div>
                            <div>
                                <a href='https://cbmp.ca/' target='_blank' rel="noopener noreferrer">CBMP</a>
                            </div>
                        </div>
                    </StyledTeamMember>
                    <StyledTeamMember>
                        <div className='team-member-photo'>
                            <img className='img-flowchart' alt='Paul Brogee' src='/images/team/paul_brogee.jpg' />
                        </div>
                        <div className='team-member-text'>
                            <div>Paul Brogee</div>
                            <div>Project Coordinator</div>
                            <div>
                                <a href='https://cbmp.ca/' target='_blank' rel="noopener noreferrer">CBMP</a>
                            </div>
                        </div>
                    </StyledTeamMember>
                </StyledTeam>
            </SubContainer>
        </Layout>
    );
}

export default Contact;
