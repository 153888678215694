import React, { useEffect, useState, useRef } from "react";
import { Toast } from 'primereact/toast';
import styled from "styled-components";
import axios from "axios";

import CustomDropdown from "../../UtilComponents/CustomDropdown";
import CustomMultiSelect from "../../UtilComponents/CustomMultiSelect";
import CustomButton from "../../UtilComponents/CustomButton";
import CustomMessages from "../../UtilComponents/CustomMessages";
import Layout from "../../UtilComponents/Layout";
import PipelineNavigation from "./PipelineNavigation";
import Loading from "../../UtilComponents/Loading";
import { useKeycloak } from "@react-keycloak/web";

const Container = styled.div`
  width: 90%;
  min-width: 1000px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FilterContainer = styled.div`
  width: 100%;
  min-width: 1000px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
`;

const FilterElement = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  .label {
    font-size: 12px;
    margin-right: 10px;
  }
  .dropdown {
    width: 150px;
  }
  .dropdown-purposes {
    width: 300px;
  }
  .dropdown-pipelines {
    width: 250px;
  }
`;

const GraphLoader = styled.div`
  width: 1000px;
  height: 540px;
`;

const DecisionTreeView = () => {
  const { keycloak } = useKeycloak();
  const toast = useRef(null);

  const handleToastClose = () => {
      if (window.gtag) { //Sending data to GA
        window.gtag('event', 'close', {
          'event_category': 'Toast Notifications',
          'event_label': '2024 Update Toast Explore Page'
        });
      }
  };

  	useEffect(() => {
		if (toast.current) {
			toast.current.show({
				severity: 'warn', 
				summary: 'CoBE Status Update', 
				detail: 
				<span>
					{'This version of CoBE is no longer receiving updates. Check the link  '}
						<a style={{textDecoration: 'none', color: 'blue' }} href='http://beta.pmcobe.ca/' target='_blank'>
							{'here'} 
						</a>
					{' to test out the new beta deployment that\'s soon to come.'}
				</span>,
				closable: true,
				life: 10000000,
			});
		}
	}, []);

  const [data, setData] = useState({
    graph: {},
    routes: [],
    filtered: false,
    initialized: true,
    ready: false,
  });
  const [datatypeDropdown, setDatatypeDropdown] = useState({
    selected: "",
    options: [],
    disabled: false,
  });
  const [purposeDropdown, setPurposeDropdown] = useState({
    selected: [],
    options: [],
    disabled: true,
  });
  const [snapshotDropdown, setSnapshotDropdown] = useState({
    selected: "",
    options: [],
  });
  const [filterPath, setFilterPath] = useState(false);

  const [message, setMessage] = useState({ show: false, message: {} });

  const initialize = async () => {
    const res = await axios.get("/api/view/decision-tree", {
      params: {
        email: keycloak.authenticated ? keycloak.idTokenParsed.email : null,
      },
    });
    setDatatypeDropdown((prev) => ({
      ...prev,
      selected: "",
      options: res.data.datatypeOptions,
    }));
    setSnapshotDropdown({
      selected: "latest",
      options: [{ value: "latest", label: "Latest" }].concat(
        res.data.snapshotOptions
      ),
    });
    setFilterPath(false);
    setData({
      ...res.data,
      initialized: true,
      filtered: false,
      ready: true,
    });
  };

  const findPipelines = async (e) => {
    e.preventDefault();
    const res = await axios.post("/api/data/graph/filter", {
      datatype: datatypeDropdown.selected,
      purposes: purposeDropdown.selected,
      filter: filterPath,
      email: keycloak.authenticated ? keycloak.idTokenParsed.email : null,
    });
    // res.data.pipelines.sort((a, b) => {
    //   return b.score - a.score;
    // });
    // console.log(res.data);
    setData({
      ...res.data,
      filtered: true,
      ready: true,
    });
  };

  const getTreeSnapshot = async (e) => {
    setSnapshotDropdown((prev) => ({
      ...prev,
      selected: e.value,
    }));
    setData((prev) => ({
      ...prev,
      ready: false,
    }));
    const res = await axios.get("/api/view/decision-tree", {
      params: { snapshot: e.value },
    });
    setData({
      graph: res.data.graph,
      pipelines: res.data.pipelines,
      tools: res.data.tools,
      initialized: true,
      filtered: false,
      ready: true,
    });
  };

  const saveTreeSnapshot = async (e) => {
    e.preventDefault();
    let error = false;
    const res = await axios.post(
      "/api/data/pipeline-snapshot/save",
      data.graph.nodes
    );
    console.log(res.data);
    if (res.status !== 200 || !res.data) {
      error = true;
    }
    let message = {
      severity: error ? "error" : "success",
      summary: error ? "Error" : "Success",
      detail: error
        ? "Snapshot could not be saved."
        : "Snapshot has been saved.",
      sticky: false,
    };
    setMessage({ show: Math.random(), message: message });
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    const update = async () => {
      let dropdown = { selected: [], options: [], disabled: true };
      if (datatypeDropdown.selected !== "") {
        const res = await axios.get(
          `/api/data/pipeline/purposes?datatype=${encodeURIComponent(
            datatypeDropdown.selected
          )}`
        );
        dropdown = { ...dropdown, options: res.data.options, disabled: false };
      }
      setPurposeDropdown(dropdown);
    };
    update();
  }, [datatypeDropdown.selected]);

  return (
    <Layout>
      <Toast ref={toast} style={{ position: 'static', width: '93%', marginTop: '15px' }}onHide={() => handleToastClose()} />
      <Container>
        <FilterContainer>
          <FilterElement>
            <span className="label">Data Type: </span>
            <CustomDropdown
              className="dropdown"
              value={datatypeDropdown.selected}
              options={datatypeDropdown.options}
              filter={true}
              onChange={(e) => {
                setDatatypeDropdown((prev) => ({ ...prev, selected: e.value }));
              }}
              placeholder="Select..."
            />
          </FilterElement>
          <FilterElement>
            <span className="label">Analysis Purpose(s): </span>
            <CustomMultiSelect
              className="dropdown-purposes"
              value={purposeDropdown.selected}
              options={purposeDropdown.options}
              disabled={purposeDropdown.disabled}
              filter={true}
              onChange={(e) => {
                setPurposeDropdown((prev) => ({ ...prev, selected: e.value }));
              }}
              placeholder="Select..."
            />
          </FilterElement>
          <FilterElement>
            <CustomButton
              className="p-button-sm"
              label="Find"
              onClick={findPipelines}
              disabled={
                datatypeDropdown.selected === "" ||
                purposeDropdown.selected.length === 0
              }
            />
          </FilterElement>
          <FilterElement>
            <CustomButton
              className="p-button-sm p-button-secondary"
              label="Reset"
              onClick={(e) => {
                e.preventDefault();
                initialize();
              }}
            />
          </FilterElement>
        </FilterContainer>
        {data.ready ? (
          <PipelineNavigation data={data} />
        ) : (
          <GraphLoader>
            <Loading />
          </GraphLoader>
        )}
        <CustomMessages trigger={message.show} message={message.message} />
        {data.initialized && (
          <FilterContainer>
            <FilterElement>
              <span className="label">Decision Tree Snapshot: </span>
              <CustomDropdown
                className="dropdown"
                value={snapshotDropdown.selected}
                options={snapshotDropdown.options}
                onChange={getTreeSnapshot}
                placeholder="Select..."
                tooltip="View the pipeline decision tree at a selected date."
              />
            </FilterElement>
            {keycloak.authenticated &&
              keycloak.idTokenParsed.email === "admin@cobe.ca" && (
                <FilterElement>
                  <CustomButton
                    className="p-button-sm"
                    label="Take a Snapshot"
                    onClick={saveTreeSnapshot}
                    disabled={
                      !data.initialized ||
                      snapshotDropdown.selected !== "latest"
                    }
                  />
                </FilterElement>
              )}
          </FilterContainer>
        )}
      </Container>
    </Layout>
  );
};

export default DecisionTreeView;
